@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap);
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Manrope', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.deleteIcon:hover {
  cursor: pointer;
  color: red;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 0;
  z-index: 1;
}

.fade-exit .fade-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-out;
}

.content{
  position: absolute;
  top: 95px;
  padding-bottom: 77.5px;
  left: 0;
  right: 0;
}

.profile-pic {
  border-radius: 50%;
  border: 2.5px solid lightblue;
}

.profile-pic:hover{
  cursor: pointer;
}
